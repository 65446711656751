<script>
export default {
  name: 'Drawing',
  props: ['placement', 'drawing'],
  data: () => ({
    scale: 100,
    inputScale: 100,
    printEventClass: 'drawing-print',
    handleDragListener: null,
    handleDragEndListener: null,
    dragging: false,
    startPos: { x: 0, y: 0 },
    scrollPos: { left: 0, top: 0 },
    transformOriginX: 0,
    transformOriginY: 0,
    lastTransformOriginX: 0,
    lastTransformOriginY: 0
  }),
  computed: {
    imageStyle() {
      return {
        transform: `scale(${this.scale / 100})`,
        transformOrigin: `${this.scale / 100 < 1 ? -this.transformOriginX : this.transformOriginX}px ${this.scale / 100 < 1 ? -this.transformOriginY : this.transformOriginY}px`
      }
    }
  },
  mounted() {
    if (this.$refs.drawing && this.$refs.container) {
      this.$refs.drawing.addEventListener('wheel', this.handleImgScroll)
      this.$refs.container.addEventListener('mousedown', this.toggleDrag)
    }
  },
  beforeDestroy() {
    if (this.$refs.drawing && this.$refs.container) {
      this.$refs.drawing.removeEventListener('wheel', this.handleImgScroll)
      this.$refs.container.removeEventListener('mousedown', this.toggleDrag)
      this.$refs.drawing.removeEventListener('mousemove', this.updateTransformOrigin)
    }
  },
  methods: {
    close() {
      this.placement === 'result'
        ? this.$emit('close')
        : this.$refs.container.classList.toggle('collapse')
    },
    print() {
      document.body.classList.add(this.printEventClass)
      window.print()
      window.onafterprint = () => {
        document.body.classList.remove(this.printEventClass)
      }
    },
    zoomIn() {
      this.scale += 1
      this.updateInputScale()
    },
    zoomOut() {
      if (this.scale > 1) {
        this.scale -= 1
        this.updateInputScale()
      }
    },
    updateScale(evt) {
      const percent = evt && parseInt(evt.target.value) ? parseInt(evt.target.value) : this.inputScale
      if (!isNaN(percent) && percent > 0) {
        this.scale = percent
      }
    },
    updateInputScale() {
      this.inputScale = this.scale
    },
    handleImgScroll(evt) {
      evt.preventDefault()
      evt.deltaY > 0 ? this.zoomOut() : this.zoomIn()
    },
    toggleDrag(evt) {
      this.dragging = !this.dragging

      if (this.dragging) {
        this.startPos = { x: evt.clientX, y: evt.clientY }
        this.scrollPos = {
          left: this.$refs.container.scrollLeft,
          top: this.$refs.container.scrollTop
        }
        this.handleDragListener = this.handleDrag.bind(this)
        this.$refs.container.addEventListener('mousemove', this.handleDragListener)
        this.$refs.container.classList.add('grabbed')
      } else {
        this.lastTransformOriginX = this.transformOriginX
        this.lastTransformOriginY = this.transformOriginY
        this.$refs.container.removeEventListener('mousemove', this.handleDragListener)
        this.$refs.container.classList.remove('grabbed')
      }
    },
    handleDrag(evt) {
      this.updateTransformOrigin(evt)
    },
    updateTransformOrigin(evt) {
      this.transformOriginX = this.lastTransformOriginX + this.startPos.x - evt.clientX
      this.transformOriginY = this.lastTransformOriginY + this.startPos.y - evt.clientY
    }
  }
}
</script>

<template>
  <section v-if="drawing" class="drawing drawing-print">
    <div class="drawing__header">
      <h3 class="drawing__title">{{ $t('message.cad.drawing') }}</h3>
      <button
        class="drawing__button drawing__button--close unprint"
        type="button"
        @click="close()"
      ></button>
    </div>
    <div class="drawing__content">
      <article class="drawing__wrapper" ref="container">
        <img
          ref="drawing"
          class="drawing__img"
          width="100"
          :style="imageStyle"
          :src="drawing"
          :alt="$t('message.cad.drawing')"
        />
      </article>
      <button
        type="button"
        class="drawing__button drawing__button--print unprint"
        @click="print"
      ></button>
      <div class="zoom-controls__wrapper unprint">
        <button
          type="button"
          class="drawing__button drawing__button--zoom drawing__button--zoom-out unprint"
          @click="zoomOut"
        ></button>
        <label for="zoom__input" class="zoom-controls__label">
          <input
            class="zoom-controls__input"
            type="text"
            v-model="inputScale"
            @input="updateScale"
            id="zoom__input"
          />
          <span>%</span>
        </label>
        <button
          type="button"
          class="drawing__button drawing__button--zoom drawing__button--zoom-in unprint"
          @click="zoomIn"
        ></button>
      </div>
    </div>
  </section>
</template>

<style lang="sass">
@mixin wrapper-for-bg-icon
  content: ''
  display: block
  width: 50%
  height: rem(3)
  background: $red
  border-radius: rem(2)
  position: relative
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.drawing
  display: flex
  flex-flow: column nowrap
  margin: rem(25) 0
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    +media((width: (320: calc(100% - 32px), 650: 92%, 960: 100%)))
    margin: 0 auto
    +media((padding: (0: rem(32) 0 rem(16), 960: rem(35) 0 rem(20))))
  &__title
    margin: 0
    @extend .fs-18
    +media((font-size: (320: rem(18), 960: rem(24))))
    font-weight: 700
    text-align: left
  &__content
    display: grid
    grid-template-columns: 1fr
    grid-template-areas: 'print' 'image' 'controls'
    justify-items: right
    grid-gap: rem(24)
    padding: rem(15) rem(20)
    background: $white
    border-radius: rem(4)
  &__wrapper
    grid-area: image
    overflow: hidden
    &.grabbed
      cursor: grab
  &__img
    display: flex
    width: 100%
  &__button
    display: flex
    width: rem(30)
    height: rem(30)
    padding: 0
    border: none
    border-radius: 50%
    cursor: pointer
    &--close
      background: $white
      &:before
        @include wrapper-for-bg-icon
    &--print
      grid-area: print
      align-self: end
      background: url(~@/assets/img/footer/print.svg) no-repeat center center
      background-color: $cool_B
      background-size: 60%
    &--zoom,
    &--zoom
      &:before
        @include wrapper-for-bg-icon
    &--zoom-in
      &:after
        @include wrapper-for-bg-icon
        width: rem(3)
        height: 50%
        left: 0
.zoom-controls
  grid-area: controls
  &__wrapper
    display: flex
    gap: rem(15)
  &__label
    position: relative
    span
      position: absolute
      display: flex
      align-items: center
      height: 100%
      top: 0
      right: 0
      padding: 0 rem(8)
      @extend .fs-12
      font-weight: 600
      text-align: center
  &__input
    display: flex
    padding: rem(4) rem(24) rem(4) rem(8)
    width: rem(30)
    @extend .fs-14
    text-align: center
    border-radius: rem(4)
    border: 1px solid $cool_B
    outline: none

.drawing:has(.collapse)
  .drawing__header
    padding-bottom: 0
  .drawing__content
    display: none
  .drawing__button--close:after
    @include wrapper-for-bg-icon
    width: rem(3)
    height: 50%
    left: 0

@media print
  body.drawing-print #app .result-page,
  body.drawing-print #app .details-page
    .container:has(.drawing) > *:not(.drawing)
      display: none!important
</style>
